<template>
  <div class="about">
    <div class="site-content">
      <div class="content-warp">
        <div class="about-site about-info">
          <section-title>关于我们</section-title>
          <div class="info-card">
            <img
              class="cardImg"
              src="https://s4.ax1x.com/2022/02/22/bSixR1.jpg"
              alt=""
            />
            <p>石家庄市世谦食品科技有限公司</p>
            <p>—————————————</p>
            <p>企业地址：河北省石家庄市赵县赵州镇桥头第一家好生利赵州饼</p>
            <p>企业邮箱：1296541164@qq.com</p>
            <p>联系方式：15321213380</p>
            <p>we-chat：zsq1296541164</p>
          </div>
        </div>
        <section-title>来访地图</section-title>
        <div class="info-card">
          <baidu-map
            class="bm-view"
            ak="03MMHCqpP5N5ZN3zh9aU16cQqdeMG0Yk"
            :center="center"
            :zoom="zoom"
            @ready="handler"
          >
            <bm-marker
              :position="{ lng: 114.776423, lat: 37.725891 }"
              :dragging="true"
              animation="BMAP_ANIMATION_BOUNCE"
            >
              <bm-label
                content="好生利·赵州饼文化店"
                :labelStyle="{ color: 'red', fontSize: '18px' }"
                :offset="{ width: -35, height: 30 }"
              />
            </bm-marker>
          </baidu-map>
        </div>

        <div class="about-me about-info">
          <section-title id="Guestbook">给我们留言</section-title>
          <form method="post" action="https://support.qq.com/product/382209">
            <div class="info-card">
              <div class="contactForm">
                <div class="form-item">
                  <label for="mail">手机</label>
                  <input class="v" type="mobile" name="nickname" id="mobile" />
                </div>
                <div class="form-item">
                  <label for="content">内容</label>
                  <textarea class="v" id="content" name="customInfo"></textarea>
                </div>
                <div class="form-item">
                  <label></label>
                  <button>提交</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import sectionTitle from "@/components/section-title";
import BaiduMap from "vue-baidu-map/components/map/Map.vue";
import { BmlMarkerClusterer, BmMarker,BmLabel} from "vue-baidu-map";

export default {
  name: "About",
  data() {
    return {
      list: [],
      center: { lng: 114.776423, lat: 37.725891 },
      zoom: 18,
    };
  },
  components: {
    sectionTitle,
    BaiduMap,
    BmMarker,
    BmLabel,
    BmlMarkerClusterer,
  },
  methods: {
    handler({ BMap, map }) {
      console.log(BMap, map);
      this.center.lng = 114.776423;
      this.center.lat = 37.725891;
      this.zoom = 18;
    },
  },
  mounted() {},
};
</script>
<style scoped lang="less">
.about {
  padding-top: 40px;
}

.content-warp {
  margin-top: 80px;

  .about-info {
    margin: 30px 0;

    span {
      color: red;
      margin-right: 10px;
    }

    .info-card {
      min-height: 100px;
      padding: 20px;
      border-radius: 3px;
      margin: 30px 0 50px 0;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      .cardImg {
        width: 50%;
      }
      p {
        line-height: 1.7rem;
      }
    }
  }
  .contactForm {
    width: 100%;
    padding: 20px;
    .form-item {
      align-items: center;
      display: flex;
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      label {
        width: 80px;
      }
      .v {
        min-height: 40px;
        line-height: 20px;
        border-radius: 3px;
        padding: 2px 10px;
        outline: none;
        border: 1px solid #8fd0cc;
        width: 100%;
        resize: vertical;
      }
      button {
        margin-left: 32%;
        width: 100px;
        height: 40px;
        border-radius: 3px;
        outline: 0;
        border-style: none;
        cursor: pointer;
        background-color: #409eff;
        color: white;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}

/*******/
@media (max-width: 800px) {
  .content-warp {
    margin-top: 0;
  }
}
.bm-view {
  width: 100%;
  height: 300px;
}
</style>
